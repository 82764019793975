var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('section',{ref:"grid",staticClass:"grid"},[_c('div',{staticClass:"blocks"},_vm._l((_vm.contents),function(item){return _c('div',{key:item.id,staticClass:"block",class:item.class.toLowerCase()},[_c('div',{staticClass:"inner"},[(item.class == 'Image')?[_c('img',{attrs:{"src":item.image.display.url,"alt":""},on:{"click":function($event){return _vm.openLightBox(
                item.image.display.url,
                item.description,
                item.title
              )}}})]:_vm._e(),(item.class == 'Link')?[_c('img',{attrs:{"src":item.image.display.url,"alt":""},on:{"click":function($event){return _vm.openLightBox(
                item.image.display.url,
                item.description,
                item.title,
                item.source.url
              )}}})]:_vm._e(),(item.class == 'Attachment')?[_c('img',{attrs:{"src":item.image.display.url,"alt":""},on:{"click":function($event){return _vm.openLightBox(
                item.image.display.url,
                item.description,
                item.title,
                item.source.url
              )}}})]:_vm._e(),(item.class == 'Channel')?[_c('div',[_c('router-link',{attrs:{"to":{ name: 'Channel', params: { id: item.slug } }}},[_vm._v(_vm._s(item.title))])],1)]:_vm._e()],2),(item)?_c('div',{staticClass:"caption"},[_c('div',[_vm._v(_vm._s(item.title))]),(item.description)?_c('div',{staticClass:"mt"},[_vm._v(_vm._s(_vm._f("shorten")(item.description)))]):_vm._e(),_c('div',{staticClass:"mt"},[_vm._v(_vm._s(item.user.username))])]):_vm._e()])}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }