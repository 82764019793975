<template lang="html">
  <section
    ref="grid"
    v-if="items"
    class="grid"
  >
    <div class="blocks">
      <div
        class="block"
        :class="item.class.toLowerCase()"
        v-for="item in contents"
        :key="item.id"
      >
        <div class="inner">
          <template v-if="item.class == 'Image'">
            <img
              @click="
                openLightBox(
                  item.image.display.url,
                  item.description,
                  item.title
                )
              "
              :src="item.image.display.url"
              alt=""
            />
          </template>
          <template v-if="item.class == 'Link'">
            <img
              @click="
                openLightBox(
                  item.image.display.url,
                  item.description,
                  item.title,
                  item.source.url
                )
              "
              :src="item.image.display.url"
              alt=""
            />
          </template>
          <template v-if="item.class == 'Attachment'">
            <img
              @click="
                openLightBox(
                  item.image.display.url,
                  item.description,
                  item.title,
                  item.source.url
                )
              "
              :src="item.image.display.url"
              alt=""
            />
          </template>
          <template v-if="item.class == 'Channel'">
            <div>
              <router-link
                :to="{ name: 'Channel', params: { id: item.slug } }"
                >{{ item.title }}</router-link
              >
            </div>
          </template>
        </div>
        <div class="caption" v-if="item">
          <div>{{ item.title }}</div>
          <div v-if="item.description" class="mt">{{ item.description | shorten }}</div>
          <div class="mt">{{ item.user.username }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    id: String,
    title: String,
    active: Boolean,
    items: Array
  },
  data() {
    return {
      width: 0
      // lightbox: {
      //   status: false,
      //   image: null,
      //   title: null,
      //   description: null
      // }
      // lightbox: false,
      // lightbox_image: null,
      // lightbox_description: null
    };
  },
  computed: {
    contents() {
      let reverse = this.items;
      return reverse;
    }
  },
  methods: {
    openLightBox(url, description, title, source) {
      let lightbox = {
        status: true,
        image: url,
        description: description,
        title: title,
        source: source
      };
      // this.lightbox.status = true;
      // this.lightbox.image = url;
      // this.lightbox.title = title;
      // this.lightbox.description = description;
      this.$root.$emit("lightbox", lightbox);
    }
  },
  filters: {
    shorten(text) {
      if (text.length > 160) {
        return text.substring(0, 160) + "...";
      } else {
        return text;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
section.grid {
  flex: 3 1 0;
  height: 100%;
  transition: flex 1s;
  overflow-y: scroll;
  scrollbar-width: none;
  .blocks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    padding: 1rem 1rem 3rem;
  }
  .mt {
    margin-top: 0.5rem;
  }
  @media screen and (max-width: 768px) {
    height: auto;
    flex: 1 1 100%;
  }

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  &.active {
    flex: 3 1 0;
    @media screen and (max-width: 768px) {
        flex: 1 1 100%;
    }
    .block {
      .caption {
        display: block;
      }
    }
  }
  .block {
    width: 100%;
    .inner {
      border: 1px solid black;
      width: 100%;
      position: relative;
      // height: var(--height);
      overflow: hidden;
      transition: height 1s;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before {
        content: "";
        padding-bottom: 100%;
        display: inline-block;
        vertical-align: top;
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        &:hover {
          // object-fit: contain;
        }
      }
    }
    .caption {
      width: 100%;
      word-break: break-all;
      // display: none;
      line-height: 1.25;
      overflow: hidden;
      margin-top: 0.5rem;
    }
  }
}

</style>
