<template>
  <main class="channel" v-if="content">
    <section class="info">
      <div class="title">{{ content.title }}</div>
      <div v-if="content.metadata" class="mbd">
        {{ content.metadata.description }}
      </div>
    </section>
    <grid
      title="Blocks"
      :class="{ active: activeIndex == 1 }"
      @mouseenter.native="activeIndex = 1"
      :items="blocks"
    />
  </main>
</template>

<script>
import Arena from "are.na";
const arena = new Arena({ accessToken: process.env.VUE_APP_ARENA });
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import Grid from "@/components/Grid.vue";
export default {
  name: "Channel",
  components: {
    Grid
  },
  data() {
    return {
      activeIndex: 0,
      width: 0,
      content: [],
      blocks: []
    };
  },
  created() {
    if(this.$route.name == 'Archive') {
      this.loadContent('archive-2vwkpvvbxss', 1);
    } else {
      this.loadContent(this.$route.params.id, 1);
    }
  },
  methods: {
    loadContent(channel, pnum) {
      arena.channel(channel)
      .get({
        page: pnum, per: 25
      })
      .then(chan => {
        console.log(chan);

        if(chan.page == 1) {
          this.content = chan;
          this.blocks = chan.contents.reverse();
        } else {
          let content = chan.contents;
          for(var i = 0; i < content.length; i++) {
            this.blocks.unshift(content[i]);
          }       
        }

        let remaining = chan.length - chan.page * chan.per;
        if(remaining > 0) {
          this.loadContent(channel, chan.page + 1);
        }
        
      })
      .catch(err => console.log(err));
    }
  },
  filters: {
    date(value) {
      return dayjs().to(dayjs(value));
    },
  }
};
</script>

<style lang="scss" scoped>
main.channel {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  section.info {
    flex: 1 1 0;
    height: 100%;
    padding: 0 1rem;
    transition: flex 1s;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
    &.active {
      flex: 2 1 0;
    }
    .title {
      padding-top: 1rem;
      padding-bottom: 1rem;
      line-height: 1.25rem;
    }
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    section.info {
      flex: 0 0 100% !important;
      height: auto;
    }
  }
}
</style>
